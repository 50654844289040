// src/PUMPA.js
import React from 'react';

const PUMPA = () => {
  // Function to handle image clicks
  const handleImageClick = (url) => () => {
    window.location.href = url;
  };

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh', 
      margin: '0 auto', 
      fontFamily: 'sans-serif', 
      color: '#170E0E', 
      backgroundColor: '#fcfcfc'
    }}>
      <h1 style={{ 
        fontSize: '30px', 
        fontWeight: '600', 
        color: '#170E0E'
      }}>PUMPA</h1>
      <div style={{ marginBottom: '16px'}}>
      <img src="/elephant.svg" alt="Pumpa Logo" style={{ width: '256px', height: 'auto', borderRadius: 16 }} />
      </div>
      <div style={{ marginBottom: '16px'}}>
        <img src="/app-store-badge.svg" alt="Download on the App Store" style={{ width: '256px', height: 'auto' }} onClick={handleImageClick("https://apps.apple.com/app/id1234567890")} />
      </div>
      <div style={{  }}>
        <img src="/google-play-badge.png" alt="Get it on Google Play" style={{ width: '256px', height: 'auto' }} onClick={handleImageClick("https://play.google.com/store/apps/details?id=io.johntech.pumpa&hl=en")} />
      </div>
      <div style={{ position: 'absolute', bottom: '16px', width: '100%', textAlign: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
          <a href="https://www.instagram.com/johntech.appdev/" target="_blank" rel="noopener noreferrer" style={{ color: '#170E0E' }}>Instagram</a>
          <span style={{ marginRight: '10px', marginLeft: '10px' }}>·</span>
          <a href="/terms" target="_blank" rel="noopener noreferrer" style={{ color: '#170E0E' }}>Terms & Conditions</a>
          <span style={{ marginRight: '10px', marginLeft: '10px' }}>·</span>
          <a href="/privacy" target="_blank" rel="noopener noreferrer" style={{ color: '#170E0E' }}>Privacy Policy</a>
        </div>
        <span>JohnTech</span>
      </div>
    </div>
  );
}

export default PUMPA;